import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AnalysisService } from '../../analysis-service.service';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-toprootcause',
  templateUrl: './toprootcause.component.html',
  styleUrls: ['./toprootcause.component.scss']
})
export class ToprootcauseComponent implements OnInit {
  @Input() DatePickerIndex:number = 0;

  startDate:string;
  endDate:string;

  objectid:string;
  site:string;
  environment:string;

  topRootCauseSub$:Subscription;
  topRootCause:any;

  loadingData:boolean=true;
  isReady:boolean=true;

  displayedColumns: string[] = ['RootCause_Level1', 'RootCause_Level2', 'RootCause_Level3', 'Downtime']

  constructor(
    private route: ActivatedRoute,
    private svcAnalysis: AnalysisService,
    private svcObjectList:ObjectListService,

  ) {
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');
   }

  ngOnInit(): void {
    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        this.environment= objectNode.Environment;
        this.isReady = true;
      }
    );
    
  }

  getTopRootCause(){
    if (this.topRootCauseSub$) this.topRootCauseSub$.unsubscribe

    this.topRootCauseSub$ = this.svcAnalysis.topRootCause(this.site, this.environment, this.objectid, this.startDate, this.endDate).subscribe(
      out => {
        // Check if we got a return, possible to get a null due to caching in the service
        if (out) {
          // Dynamically set the list of columns
          this.loadingData=false;
          
          this.topRootCause = out.Body;
        }
      }
    );
  }

  onDateSelection(e) {
    console.log('ON DATE SELECTION')
    this.startDate = dayjs(e.start).format('YYYY-MM-DD'); 
    console.log(this.startDate)
    this.endDate = dayjs(e.end).format('YYYY-MM-DD'); 
    console.log(this.endDate)
    this.loadingData = true;
    this.getTopRootCause();
  }
}
