import { Component, OnInit } from '@angular/core';
import { AdminService, PlantType } from '../../services/admin.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  objectList$: Subscription;
  mDataSource: {};
  plantList: PlantType[];

  selectedSite:string="";
  activeLink:string="objects";

  constructor(
    private svcAdmin: AdminService) {}

  ngOnInit(): void {
    this.getSiteObjectList();
  }

  getSiteObjectList() {
    this.svcAdmin.plantList().subscribe((
      out => {
        this.plantList = out.Body;
      }
    ));
  }

  selectSite(site: string) {
    this.selectedSite = site;
  }
}