import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AnalysisService extends AbstractRestService {

  postInit(): void {
    console.debug("AnalysisServiceService Init Complete");
  }

  rawData(site:string,objectid:string,startDate:string, endDate:string, shift:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.StartDate = startDate;
    params.EndDate = endDate;
    params.Shift = shift; // Comma delimit list of shifts

    return this.get({
      ApiResource: "/analysis/data",
      data: params,
      repeatCall: false
    });
  }

  faultList(site:string, environment:string, objectid:string, startDate:string, endDate:string, shift:string, level1:string, level2:string, level3:string, hour:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.Site = site;
    params.Environment = environment;
    params.ObjectID = objectid;
    params.StartDate = startDate;
    params.EndDate = endDate;
    params.Shift = shift;
    params.Level1 = level1;
    params.Level2 = level2;
    params.Level3 = level3;
    params.Hour = hour;

    return this.get({
      ApiResource: "/pareto/faultlist",
      data: params,
      repeatCall: false
    });
  }

  topRootCause(site:string,environment:string,objectid:string,startDate:string, endDate:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.Site = site;
    params.Environment = environment;
    params.ObjectID = objectid;
    params.StartDate = startDate;
    params.EndDate = endDate;

    return this.get({
      ApiResource: "/analysis/toprootcause",
      data: params,
      repeatCall: false
    });
  }

  plannedDowntime(site:string,environment:string,objectid:string,startDate:string,endDate:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.ObjectID = objectid;
    params.Site = site;
    params.Environment = environment;
    params.StartDate = startDate;
    params.EndDate = endDate;

    return this.get({
      ApiResource: "/analysis/planned",
      data: params,
      repeatCall: false
    });
  }


}
