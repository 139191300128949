<app-button-bar [site]="site" [objectid]="objectid"></app-button-bar>

<div style="padding: 15px">
    <app-metrics-header [object]="object" section=""></app-metrics-header>

    <div *ngIf="proficyOASetup">
        <div class="actionButtons">
            <div style="min-width: 144px">
                <button mat-icon-button color="primary" (click)="openConfig()"><mat-icon>settings</mat-icon> Configuration</button>
            </div>
        </div>
        <div *ngIf="shiftData.currentShift && activeEvent">
            <!-- Current Planned Downtime event -->
            <mat-card  class="downtime-card mat-elevation-z10">
                <mat-card-header>
                    <mat-card-title>
                        {{activeDescription}}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="errorMesg == '' && activeCountDownCurrent > 0" [ngClass]="activeCountDownCurrent <= 10 ? 'warning-time' : null">
                        Estimated Remaining Time: {{activeCountDownCurrent | TimeFormat }}
                    </div>
                    <div *ngIf="activeCountDownCurrent == 0" class="over-time" >
                        Over Planned Time
                    </div>
                    <div *ngIf="errorMesg != ''" class="over-time">
                        {{errorMesg}}
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <button *ngIf="!processing" mat-button color="warn" (click)="stopEvent(activeDescription)"><mat-icon>check_circle_outline</mat-icon>Stop {{activeDescription}}</button>
                    <div *ngIf="processing" style="padding-left: 10px; width: 200px">
                        {{processingMesg}}
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>

        <div *ngIf="!shiftData.currentShift || !activeEvent" >
            <!-- No Current Event in Progress, display listed of planned downtime -->
            <mat-card *ngFor="let d of configData" class="downtime-card mat-elevation-z10">
                <mat-card-header>
                    <mat-card-title >
                        {{d.Description}}
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                        Estimated Duration: {{d.Duration | TimeFormat }}
                </mat-card-content>
                <mat-card-footer>
                    <mat-card-actions>
                        <button [disabled]="!shiftData.currentShift" *ngIf="!processing" mat-button color="primary" (click)="startEvent(d.ConfigID, d.Description,d.Duration)"><mat-icon>build</mat-icon>Start {{d.Description}}</button>
                        <div *ngIf="processing && d.ConfigID == processingID" style="padding-left: 10px; width: 200px">
                            {{processingMesg}}
                            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                        </div>
                    </mat-card-actions>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>


    <div *ngIf="!proficyOASetup">
        Proficy not setup for OA for this piece of equipment. Please reach out to FIS to have them complete the configuration.
    </div>
</div>