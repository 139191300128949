<div style="padding: 5px 5px 5px 5px">
    <ng-container *ngFor="let d of plantList; let i = index" >
        <button mat-raised-button color="primary" class="adminSiteButtons" (click)="selectSite(d.display_name)">{{d.display_name}}</button>
    </ng-container>
</div>

<div *ngIf="selectedSite != ''">
    <mat-toolbar color="primary" style="padding: 5px">Plant Selected: {{selectedSite}}</mat-toolbar>
    <nav mat-tab-nav-bar>
        <a mat-tab-link
            id="object-tab"
            [ngClass]="{'active-tab': activeLink=='objects'}"
            (click)="activeLink = 'objects'"
            [active] = "activeLink == 'objects'">
        Objects</a>
        <a mat-tab-link
            id="problemgroups-tab"
            [ngClass]="{'active-tab': activeLink=='problemgroups'}"
            (click)="activeLink = 'problemgroups'"
            [active] = "activeLink == 'problemgroups'">
        Problem Groups</a>
        <a mat-tab-link
            id="teams-tab"
            [ngClass]="{'active-tab': activeLink=='teams'}"
            (click)="activeLink = 'teams'"
            [active] = "activeLink == 'teams'">
        Teams</a>
    </nav>

    <mat-tab-nav-panel *ngIf="activeLink == 'objects'">
        <div class="tab-content">
            <app-admin-objects
                [selectedSite]="selectedSite">
            </app-admin-objects>
        </div>
    </mat-tab-nav-panel>

    <mat-tab-nav-panel *ngIf="activeLink == 'problemgroups'">
        <div class="tab-content">
            <app-admin-problem-groups
                [selectedSite]="selectedSite">
            </app-admin-problem-groups>
        </div>
    </mat-tab-nav-panel>

    <mat-tab-nav-panel *ngIf="activeLink == 'teams'">
        <div class="tab-content">
            <app-team-groups
                [selectedSite]="selectedSite"
            ></app-team-groups>
        </div>
    </mat-tab-nav-panel>
</div>