import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { OAService } from '../oa.service';
import { MetricsService, shiftData } from 'src/app/services/metrics.service';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';
import { filter, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PlannedConfigDialog } from './planned-config-dialog/planned-config-dialog.component';
import { OaChallengeDialogComponent } from 'src/app/admin/components/oa-challenge-dialog/oa-challenge-dialog.component';

@Component({
  selector: 'app-planned-downtime',
  templateUrl: './planned-downtime.component.html',
  styleUrls: ['./planned-downtime.component.scss']
})
export class PlannedDowntimeComponent implements OnInit, OnDestroy {
  objectid:string;
  site:string;
  environment:string;

  object:ObjectNode;

  currentEvent$:Subscription;

  configEvent$:Subscription;
  configData:[];

  proficyOASetup:boolean = true;

  cycleTime:number = 1;
  objectType:string = "ASSEMBLY";

  activeEvent:boolean;
  activeDescription:string;
  activeCountDownCurrent:number = 0;

  countTimer$:Subscription;

  shiftData:shiftData;

  errorMesg:string = "";

  processing:boolean=false;
  processingID:number;
  processingMesg:string;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private svcMetrics:MetricsService,
    private svcObjectList:ObjectListService,
    private svcOA: OAService
  ) { }

  ngOnInit(): void {
    this.site = this.route.snapshot.paramMap.get('site');
    this.objectid = this.route.snapshot.paramMap.get('objectid');

    // Load the Object Config
    this.svcObjectList.getObjectConfig(this.site, this.objectid).pipe(filter(out => out != null), take(1)).subscribe(
      (objectNode:ObjectNode) => {
        this.object = objectNode;

        this.objectType = objectNode.ObjectType;
        this.environment = objectNode.Environment;

        this.getHeaderData();
      }
    );

    this.svcMetrics.getShiftData().subscribe(
      out => {
        this.shiftData = out;
      }
    )
  }

  setProcessing(status:boolean, processingMesg:string="", processingID:number=-1) {
    this.processingID = processingID;
    this.processingMesg = processingMesg;
    this.processing = status;
  }

  getHeaderData() {
    this.svcMetrics.metricsHeader(this.object).pipe(filter(out => out != null && out['Body'].PRODUCTION.Plant == this.site && out['Body'].PRODUCTION.Name == this.objectid),take(1)).subscribe(
      out => {
        if (out) {
          let metricsHeaderData = out.Body.PRODUCTION;
          this.cycleTime = metricsHeaderData['idealCycleTime']

          if (metricsHeaderData["OAActual"] != -999) {
            this.proficyOASetup = true;

            this.getCurrentEvent();
            this.getConfig();

          } else {
            this.proficyOASetup = false;
          }
        }
      }
    );    
  }

  getConfig() {
    if (this.configEvent$) this.configEvent$.unsubscribe();

    this.configEvent$ = this.svcOA.getPlannedDowntimeConfig(this.site, this.environment, this.objectid).subscribe(
      (out) => {
        if (out) {
          this.configData = out["Body"];
        }
      }
    );
  }


  getCurrentEvent() {
    if (this.currentEvent$) this.currentEvent$.unsubscribe();

    this.currentEvent$ = this.svcOA.getCurrentPlannedDowntime(this.site, this.environment, this.objectid).subscribe(
      (out) => {
       
        if (out) {
          let activeEvent = out["Body"];

          this.activeEvent = activeEvent['Active'];
          this.activeDescription = activeEvent['Description'];

          if (this.activeEvent) {
            this.setProcessing(false);

            if (this.activeCountDownCurrent == 0 || this.activeCountDownCurrent > activeEvent['RemainingTime']) {
              this.activeCountDownCurrent = activeEvent['RemainingTime'];
            }

            if (this.activeCountDownCurrent > 0 && (!this.countTimer$ || this.countTimer$.closed)) {
              this.startTimer();
            }

          } else  {
            if (this.countTimer$) this.countTimer$.unsubscribe();

            this.activeCountDownCurrent = 0;
          }
        }
      }
    )
  }

  startTimer() {
    if (!this.countTimer$ || this.countTimer$.closed) {
      this.countTimer$ = interval(1000).subscribe(
        x => {
          if (this.activeCountDownCurrent > 0) {
            this.activeCountDownCurrent -= 1; // Substract one second from the timer
          } else {
            this.countTimer$.unsubscribe(); // once reaching zero, no longer need the timer
          }
        }
      );
    } else {
      console.debug("Timer already started, don't start it again");
    }
  }

  startEvent(configID:number, description:string, duration:number) {
    this.setProcessing(true, `Starting ${description}...`, configID);
    this.errorMesg = "";

    if (this.currentEvent$) this.currentEvent$.unsubscribe();

    this.svcOA.startPlannedDowntime(this.site, this.environment, this.objectid, configID).subscribe(
      (out) => {
        this.getCurrentEvent(); // Start watching for events to start again
      },
      (error) => {
        // A failure occurred, stop the timer and set a message to display to the user
        console.error("ERROR OCCURRED!!!", error);
        this.errorMesg = `Error communicating with Proficy, unable to start ${description}.`
        if (this.countTimer$) this.countTimer$.unsubscribe(); 
      }
    );

    // We want the interface to feel responsive, starting the timer immediately and allow getCurrentEvent() to sync up later
    this.activeDescription = description;
    this.activeCountDownCurrent = duration;
    this.activeEvent = true;
    this.setProcessing(false);
    this.startTimer();
  }

  stopEvent(description:string) {
    if (this.countTimer$) this.countTimer$.unsubscribe();
    if (this.currentEvent$) this.currentEvent$.unsubscribe(); // Stop watching for events

    this.errorMesg = "";

    this.setProcessing(true, `Stopping ${description}...`);

    this.svcOA.stopPlannedDowntime(this.site, this.environment, this.objectid).subscribe(
      (out) => {
        this.activeEvent = false;
        this.setProcessing(false);
        this.getCurrentEvent(); // Start watching for events to start again
      },
      (error) => {
        // A failure occurred, stop the timer and set a message to display to the user
        console.error("ERROR OCCURRED!!!", error);
        this.errorMesg = `Error communicating with Proficy, unable to stop ${description}.`
        this.getCurrentEvent(); // Start watching for events to start again, in case this clears itself up
      }
    );

  }

  /**
   * Opens the Planned Donwtime config dialog, when the dialog closes if changes were made then reload the configuration on this screen
   */
  openConfig() {
    const dialogRef = this.dialog.open(PlannedConfigDialog, {
      width: '675px',
      maxHeight: '800px',
      data: {
        objectid: this.objectid,
        site: this.site,
        environment: this.environment
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.updates) {
          this.getConfig(); // Reload the config data as it might have chnaged
        }
      }
    });
  }

  openOAConfig() {
    const dialogRef = this.dialog.open(OaChallengeDialogComponent, {
      width: '675px',
      maxHeight: '800px',
      data: {
        objectid: this.objectid,
        site: this.site,
        environment: this.environment
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.updates) {
          this.getConfig(); // Reload the config data as it might have chnaged
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.countTimer$) this.countTimer$.unsubscribe();
    if (this.currentEvent$) this.currentEvent$.unsubscribe();
    if (this.configEvent$) this.configEvent$.unsubscribe();
  }

}
