<h3 mat-dialog-title><mat-icon>smart_toy_outline</mat-icon> Shift Summary - Generated by Google Gemini</h3>
<div mat-dialog-content style="overflow-y:scroll; max-height:50vh;">
    <div *ngIf="!isLoading">
        <markdown ngPreserveWhitespaces [data]="summary"></markdown>
    </div>
    
    <div *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        {{status}}
    </div>

</div>

<div mat-dialog-actions class="fx-row-end-center">
    <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>