<div class="button-container">
    <div class="button-bar">
        <button mat-stroked-button color="primary" style="border: 0px" (click)="navToHome()">Home</button>
        <button mat-raised-button color="primary" *ngIf="currentObject" [matMenuTriggerFor]="objects">{{currentObject.Description}}
            <mat-icon style="color: white;">arrow_drop_down</mat-icon>
        </button>
        

        <!-- Render the Object drop down menu-->
        <mat-menu #objects="matMenu">
            <ng-container  *ngFor="let d of siteObjects['children']; index as i;">
                <button mat-menu-item [matMenuTriggerFor]="submenu">{{d.Description}}</button>
                <mat-menu #submenu="matMenu">
                    <ng-container  *ngFor="let sub of d['children'];">
                        <button *ngIf="sub.SubSection == ''" mat-menu-item [ngClass]="sub.ObjectType == 'GROUP' ? 'GroupObject' : null" (click)="changeObject(sub.Search, sub.ObjectID)">
                            <span *ngIf="sub.ObjectType == 'GROUP'"><mat-icon>grid_view</mat-icon></span>
                            <span *ngIf="sub.ObjectType != 'GROUP'"><mat-icon>list_alt</mat-icon></span>
                            {{sub.Description}}
                        </button>
                        <!-- Sub Menu for Sub Section -->
                        <button *ngIf="sub.SubSection != ''" mat-menu-item [matMenuTriggerFor]="subsectionmenu"><mat-icon>list</mat-icon>{{sub.Description}}</button>
                        <mat-menu #subsectionmenu="matMenu">
                            <ng-container  *ngFor="let sub2 of sub['children'];">
                                <button mat-menu-item [ngClass]="sub2.ObjectType == 'GROUP' ? 'GroupObject' : null" (click)="changeObject(sub2.Search, sub2.ObjectID)">
                                    <span *ngIf="sub2.ObjectType == 'GROUP'"><mat-icon>grid_view</mat-icon></span>
                                    <span *ngIf="sub2.ObjectType != 'GROUP'"><mat-icon>list_alt</mat-icon></span>
                                    {{sub2.Description}}
                                </button>
                            </ng-container>
                        </mat-menu>
                    </ng-container>
                </mat-menu>
            </ng-container>
        </mat-menu>
        <!-- END: Render the Object drop down menu-->

        <button mat-button [ngClass]="currentRoute == 'metrics' ? 'mat-raised-button' : 'mat-stroked-button'" style="border: 0px" color="primary" (click)="navToMetrics()">Metrics</button>
        <button mat-button [ngClass]="currentRoute == 'oa/planneddowntime' ? 'mat-raised-button' : 'mat-stroked-button'" style="border: 0px" color="primary" *ngIf="objectType == 'FAB'" (click)="navToOAPlannedDownTime()">Planned Downtime</button>
        <button mat-button [ngClass]="currentRoute == 'oa/customerdemand' ? 'mat-raised-button' : 'mat-stroked-button'" style="border: 0px" color="primary" *ngIf="objectType == 'FAB'" (click)="navToOACustomerDemand()">Customer Demand</button>
        <button mat-button [ngClass]="currentRoute == 'testrepair' ? 'mat-raised-button' : 'mat-stroked-button'" style="border: 0px" color="primary" *ngIf="showTestRepair" (click)="navToTestRepair()">Test and Repair</button>
        <button mat-button [ngClass]="currentRoute == 'shiftnote' ? 'mat-raised-button' : 'mat-stroked-button'" style="border: 0px" color="primary" (click)="openShiftNote()">Shift Note</button>
        <!-- <button mat-button [ngClass]="currentRoute == 'inventory' ? 'mat-raised-button' : 'mat-stroked-button'" style="border: 0px" color="primary" (click)="navToInventory()">Inventory</button> -->
        <button mat-button class="hide-mobile" [ngClass]="currentRoute == 'pamatrix' ? 'mat-raised-button' : 'mat-stroked-button'" style="border: 0px" color="primary" (click)="navToPAMatrix()">P & A Matrix</button>
        <button mat-button class="hide-mobile" [ngClass]="currentRoute == 'analysis' ? 'mat-raised-button' : 'mat-stroked-button'" style="border: 0px" color="primary" (click)="navToAnalysis()">Analysis</button>
    </div>

    <div *ngIf="shiftSelect != 'false' && shiftData">
        <div class="shift-section"> 
            <div class="shift-section-times">
                <div>{{timestamp}}</div>
                <div [ngClass]="!shiftData.currentShift ? 'previousShift' : null" class="shift-section-shiftdate">
                    <div *ngIf="shiftData.dataLoading"><mat-icon class="blink_me">download</mat-icon> Loading... </div> 
                    <div class="shift-date">Shift Date: {{shiftData.display.shiftDate}}</div>
                    <div>{{shiftData.display.shift}}</div>
                </div>
            </div>
            <div class="fx-row-start-end">
                <div><button mat-button (click)="changeShiftDate()"><mat-icon>calendar_today</mat-icon></button></div>
                <div>
                    <button mat-raised-button color="primary" [ngClass]="!shiftData.currentShift ? 'previousShiftbutton' : null" (click)="changeShift()">
                        <span *ngIf="currentShift">Current Shift</span>
                        <span *ngIf="!currentShift">Previous Shift</span>
                    </button>
                </div>
            </div> 

        </div>
    </div>
</div>