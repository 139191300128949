
<div class="filter-options">
      <gea-mes-datepicker
      class="dateRangeBut"
      matTooltip="Select Date" 
      (onDateSelect)="onDateSelection($event)" 
      (onDateInit)="onDateSelection($event)"
      singleDate="false" 
      showLabel="true"
      showCurrentShift="false"
      defaultRange="Today"
      showRangeLabel="true"
      autoApply="true"
      [index]=DatePickerIndex>
      </gea-mes-datepicker>
      <div>
      <mat-form-field class="exclude-box">
            <mat-label>Exclude from Chart</mat-label>
            <mat-select [formControl]="filter" multiple (selectionChange)="applyFilter()">
              <mat-option *ngFor="let item of formattedData" [value]="item.label">{{item.label}}</mat-option>
            </mat-select>
      </mat-form-field>
      </div>

      <div>
      <mat-form-field>
            <mat-label>Select Shift</mat-label>
            <mat-select [(value)]="shifts.values" [formControl]="shiftSelect" multiple (selectionChange)="getParetoData()">
              <mat-option *ngFor="let shift of shifts" [value]="shift">{{shift}}</mat-option>
            </mat-select>
      </mat-form-field>
      </div>

      <div>
            <mat-radio-group aria-label="Select Grouping">
                  <mat-radio-button value="1" (change)="setGrouping('occurences')">Occurences</mat-radio-button>
                  <mat-radio-button value="2" checked (change)="setGrouping('duration')">Duration (Seconds)</mat-radio-button>
                  <mat-radio-button value="3" (change)="setGrouping('units')">Lost Units</mat-radio-button>
            </mat-radio-group>
      </div>

      <div>
            <button mat-button color="primary" (click)="setMinSeconds()">
                  <mat-icon>schedule</mat-icon>
                  <span *ngIf="minSeconds == 0">No Filter</span>
                  <span *ngIf="minSeconds != 0">Filter {{minSeconds}} seconds</span>
            </button>
      </div>

      <div>
            <button mat-button color="primary" (click)="toggleDetail()">
                  <mat-icon>format_list_numbered</mat-icon>
                  <span *ngIf="!showDetail">Show Level Detail</span>
                  <span *ngIf="showDetail">Hide Level Detail</span>
            </button>
      </div>

      <div>
            <button mat-button color="primary" style="color: green" (click)="openFaultDetails(filterLevel1, filterLevel2, '')"><mat-icon>info</mat-icon> Details</button>
      </div>
</div>
<div>
      <button *ngIf="filterLevel1 != ''" mat-button class="siteSelectBackButton"  color="primary" (click)="clearFilter()"><mat-icon>arrow_back</mat-icon></button>
      <button *ngIf="filterLevel1 != ''" mat-button color="primary" (click)="clearFilter(2)">{{filterLevel1}}</button>
      <button *ngIf="filterLevel2 != ''" mat-button color="primary">{{filterLevel2}}</button>
         
</div>
<div>
      <fusioncharts
      height="80%"
      width="100%"
      type="pareto3d"
      dataFormat="JSON"
      [dataSource]="dataSource"
      (dataplotClick)="drilldown($event)"
    ></fusioncharts>
</div>
<div *ngIf="loadingData">
      <mat-spinner></mat-spinner>
</div>