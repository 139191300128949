import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { interval, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MetricsService } from 'src/app/services/metrics.service';

@Component({
  selector: 'app-shift-summary-dialog',
  templateUrl: './shift-summary-dialog.component.html',
  styleUrls: ['./shift-summary-dialog.component.scss']
})
export class ShiftSummaryDialog implements OnInit, OnDestroy {

  summary:string;
  isLoading:boolean=true;
  status:string;

  statusMessages:string[] = [
    'Sending data to Gemini...', // 0 Seconds in, statusIndex = 0
    'Processing data...', // 5 Seconds in, statusIndex = 1
    'Analyzing metrics...', // 10 Seconds in, statusIndex = 2
    'Analyzing downtime and root cause data...', // 15 Seconds in, statusIndex = 3
    'Continuing to analyze shift data...', // 20 Seconds in, statusIndex = 4
    'Continue to process data, taking longer than expected...', // 25 Seconds in, statusIndex = 5
    'Continuing to process data...', // 30 Seconds, statusIndex = 6
    'Having trouble generating the sumamry, please wait...', // 35 seconds in, something is wrong, statusIndex = 7
    'Ran into problems, going to try again...', // 40 seconds in, not looking good, statusIndex = 8
    'Reloading...', // 45 seconds in, we need to reset and try again, statusIndex = 9
  ];
  statusIndex:number=0;
  totalCounter:number = 0;

  summaryLoaded$: Subject<boolean> = new Subject<boolean>();
  summarySub$: Subscription;
  
  constructor(
    public dialogRef: MatDialogRef<ShiftSummaryDialog>,
    private svcMetrics: MetricsService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { }

  ngOnInit(): void {
    this.status = this.statusMessages[0]; // Show the initial status message
    this.loadSummary(); // Start loading the summary
    this.updatePeriodicStatus(); // Start updating the status message and the monitor process to catch a failure
  }

  ngOnDestroy(): void {
    this.summaryLoaded$.next(true); // Signal we've loaded the summary loaded to stop the interval tracker
    if (this.summarySub$) this.summarySub$.unsubscribe();
  }

  updatePeriodicStatus(): void {
      // Every 5 seconds, change the message on the screen
      const seconds = interval(5000);

      seconds.pipe(takeUntil(this.summaryLoaded$)).subscribe(
        (out) => {
          this.status = this.statusMessages[this.statusIndex]; // Change the status message
  
          // Increment the index or set back to 0
          this.statusIndex += 1; // Incremenet the statusIndex
          this.totalCounter += 1; // Keep track of the total number of times
  
          if (this.totalCounter > 17) {
            // We've waited too long, this isn't going to work just abort the attempt
            console.error("FAILED TO LOAD SHIFT SUMMARY!!!");
            this.summarySub$.unsubscribe(); // Stop trying, we're done
            this.summaryLoaded$.next(true); // Send signal to stop this interval counter
            this.status = 'FAILED TO GENERATE SUMMARY, PLEASE CLOSE AND TRY AGAIN...'; // Display our faliure to the user
          } else if ( this.statusIndex >= this.statusMessages.length) {
            // Going to assume something has gone wrong, so force a retry
            this.statusIndex = 0; // Reset the index
            this.status = this.statusMessages[0];
            this.loadSummary(); // Kill the current process and try again
          }
        }
      )
  }

  loadSummary(): void {
    if (this.summarySub$) this.summarySub$.unsubscribe();

    this.svcMetrics.summary().pipe(take(1)).subscribe(
      out => {
        if ("errorType" in out) {
          // For some reason the Gemini library returns a runtime error sometimes and it doesn't come back as a failure, so doing this to retry
          console.error("SUMMARY: Failed", out);
          this.loadSummary(); // Just try again
        } else {
          this.summary = out.Body;
          this.isLoading = false; // Hide the progress bar and status message
          this.summaryLoaded$.next(true); // Signal that the summary has been loaded
        }
      }
    );
  }

  onNoClick():void {
    this.dialogRef.close();
  }

}
