<form [formGroup]="noteFormGroup">
    <h3 mat-dialog-title>Shift Notes</h3>
    <div mat-dialog-content>
        <div>
            <div>
                <mat-label>Add New Note:</mat-label>
            </div>
            <mat-form-field [style.width.px]="450" appearance="outline">
                <textarea matInput rows="5" cols="40" placeholder="Add New Note" formControlName="Note"></textarea>
            </mat-form-field>
        </div>

        <div>
            <mat-form-field>
                <mat-select placeholder="Zone" formControlName="Zone" (selectionChange)="updateFilterShiftNotes()">
                    <mat-option *ngFor="let d of zoneData" [value]="d.zone_id">{{d.zone_id}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <button mat-raised-button color="primary" (click)="saveNote()" [disabled]="noteFormGroup.invalid">Save</button>
        </div>
        <div>
            <h4>Existing Notes:</h4>
            <div style="overflow-y:scroll; max-height:200px;">
                <mat-table [dataSource]="filteredShiftNotes">

                    <ng-container matColumnDef="Note">
                        <mat-cell *matCellDef="let d">{{d.Note}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Action">
                        <mat-cell *matCellDef="let d"><button mat-button color="primary" (click)="editNote(d)"><mat-icon>create</mat-icon></button></mat-cell>
                    </ng-container>

                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </div>
        </div>
    </div>

    
    <div mat-dialog-actions class="fx-row-end-center">
        <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
    </div>
</form>