import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { ObjectDialogComponent, ObjectConfigType } from '../object-dialog/object-dialog.component';
import { AndonGroupsDialogComponent, AndonGroupsType } from '../andon-groups-dialog/andon-groups-dialog.component';

// Services
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { AdminService } from '../../services/admin.service'
import { ProblemsComponent } from '../problems/problems.component';
import { OaChallengeDialogComponent } from '../oa-challenge-dialog/oa-challenge-dialog.component';

@Component({
  selector: 'app-admin-objects',
  templateUrl: './objects.component.html',
  styleUrls: ['./objects.component.scss']
})
export class ObjectsComponent implements OnChanges, OnDestroy {
  @Input() selectedSite:string="";
    
  fullSizeMatcher: MediaQueryList;
  
  objectList$: Subscription;
  adminFlag: boolean;
  adminSub$: Subscription;

  mDataSource: {};
  objectList: any[] = [];

  displayedObjectColumns: string[] = ['Section','SubSection','ObjectID','Description','ObjectType','ProblemGroupDescription','actions']

  constructor(
    private svcAdmin: AdminService,
    private mediaMatcher: MediaMatcher,
    public dialog: MatDialog,
    private geaMesCognito: GeaMesCognitoAuthService
  ) {
    this.fullSizeMatcher = this.mediaMatcher.matchMedia('(min-width: 1024px)');

    // Check to see if this user is an admin
    this.adminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.adminFlag = out;
      if (this.adminSub$) this.adminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });
   }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Changes", changes);
    if (changes.selectedSite.currentValue != "" && changes.selectedSite.currentValue != changes.selectedSite.previousValue) {
      this.getSiteObjectList();
    }
   }


  ngOnDestroy(): void {
    if (this.objectList$) this.objectList$.unsubscribe();
  }

  getSiteObjectList() {
    if (this.objectList$) this.objectList$.unsubscribe();

    this.objectList$ = this.svcAdmin.objectList(this.selectedSite).subscribe((
      out => {
        console.debug("ObjectList", out);
        this.mDataSource = out.Body;
        this.objectList = out.Body;
      }
    ));
  }

  selectSite(site: string) {
    console.debug(site)
    this.selectedSite = site;
    this.getSiteObjectList();
  }

  andonGroups(Site:string, Environment:string, ObjectID:string, Description:string) {
    let data:AndonGroupsType = {
                                  "Site": Site,
                                  "Environment": Environment,
                                  "ObjectID": ObjectID,
                                  "Description": Description
                                };

    const dialogRef = this.dialog.open(AndonGroupsDialogComponent, {
      width: '500px',
      maxHeight: '800px',
      data: {objData: data}
    });    

  }

    openOAConfig(Site:string, Environment:string, ObjectID:string) {
      const dialogRef = this.dialog.open(OaChallengeDialogComponent, {
        width: '675px',
        maxHeight: '800px',
        data: {
          objectid: ObjectID,
          site: Site,
          environment: Environment
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.updates) {
            this.getSiteObjectList(); // Reload the config data as it might have chnaged
          }
        }
      });
    }

  editObject(isEdit: boolean, index:number = -1) {
    console.log("EDITING THIS OBJECT!!!",index);
    console.log("OBJECT", this.objectList[index]);

    let data:ObjectConfigType;

    if (index != -1) {
      data = {
        "isEdit": isEdit,
        "ObjectID": this.objectList[index].ObjectID,
        "ObjectType": this.objectList[index].ObjectType,
        "Description": this.objectList[index].ManualDescription,
        "PlantName": this.selectedSite,
        "ManCount": this.objectList[index].ManCount,
        "CountObjectID": this.objectList[index].CountObjectID,
        "ManProb": this.objectList[index].ManProb,
        "ProbNoteReq": this.objectList[index].ProbNoteReq,
        "ProbLevelReq": this.objectList[index].ProbLevelReq,
        "ProblemGroup": this.objectList[index].ProblemGroup,
        "RepairLine": this.objectList[index].​RepairLine,
        "RepairLocation": this.objectList[index].​RepairLocation,
        "SchemaName": this.objectList[index].​SchemaName,
        "Section": this.objectList[index].​Section,
        "SubSection": this.objectList[index].SubSection,
        "TestArea": this.objectList[index].​TestArea,
        "TestLine": this.objectList[index].​TestLine,
        "TestStation": this.objectList[index].​TestStation,
        "TestType": this.objectList[index].​TestType,
        "TeamGroup": this.objectList[index].TeamGroup,
        "ExcludeTimerGroups": this.objectList[index].ExcludeTimerGroups
      }
    } else {
      data = {
        "isEdit": isEdit
      }
    }

    console.log("OPEN DIALOG!!",data);

    const dialogRef = this.dialog.open(ObjectDialogComponent, {
      width: '800px',
      maxHeight: '850px',
      data: {objData: data}
    });

    dialogRef.afterClosed().subscribe(result => {
      let site = result;
      if(site) {
        this.selectSite(site);
      } else {
        this.getSiteObjectList();
      }
    });    

  }

  deleteObject(Site:string, Environment:string, ObjectID:string) {
    if (confirm("Are you sure to delete "+ObjectID)) {
      this.svcAdmin.deleteObject(Site, Environment, ObjectID).subscribe((
        out => {
            console.debug(out);
            this.getSiteObjectList();
      }));
    }
  }

  editProblems(problemGroup:number, description:string): void {
    const dialogRef = this.dialog.open(ProblemsComponent, {
      width: '750px',
      maxHeight: '850px',
      data: {
        problemGroup: problemGroup,
        description: description
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("close dialog", result);
    });
  }

}
