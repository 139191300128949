import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { ObjectNode } from './object-list.service';

@Injectable({
  providedIn: 'root'
})
export class MetricsService extends AbstractRestService {

  private objectid:string;
  private PerJobObjectID:string;
  private site:string;
  private environment:string;
  private objecttype:string;

  private shiftdate:string;
  private shift:string;
  private hour:string;
  private minduration:number;

  private currentShift:boolean=true;
  private currentShiftSubject:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); 

  private metricsHeaderData:BehaviorSubject<GenericLambdaReturn<any>> = new BehaviorSubject<GenericLambdaReturn<any>>(null); 
  private metricsHeaderSub$: Subscription;

  private metricsHourData:BehaviorSubject<GenericLambdaReturn<any>> = new BehaviorSubject<GenericLambdaReturn<any>>(null); 
  private metricsHourSub$: Subscription;

  private metricsTopProblemsData:BehaviorSubject<GenericLambdaReturn<any>> = new BehaviorSubject<GenericLambdaReturn<any>>(null); 
  private metricsTopProblemsSub$: Subscription;

  private problemsData:BehaviorSubject<GenericLambdaReturn<any>> = new BehaviorSubject<GenericLambdaReturn<any>>(null); 
  private problemsSub$: Subscription;

  private faultListData:BehaviorSubject<GenericLambdaReturn<any>> = new BehaviorSubject<GenericLambdaReturn<any>>(null); 
  private faultListSub$: Subscription;

  private partCountData:BehaviorSubject<GenericLambdaReturn<any>> = new BehaviorSubject<GenericLambdaReturn<any>>(null); 
  private partCountSub$: Subscription;

  private shiftData:BehaviorSubject<shiftData> = new BehaviorSubject<shiftData>(null);
  private lastMetricsLoadTime:BehaviorSubject<Date> = new BehaviorSubject<Date>(null);
  

  postInit(): void {
    console.debug("MetricsService Init Complete");
  }

  /**
   * Check all BehaviorSubjects for any observables, if none that means nothing is using the data so unsubscribe. This
   * should be called in ngOnDestroy for anything using this service.
   */
  cleanupObservers():void {
    if (this.metricsHeaderData.observers.length == 0) {
      if (this.metricsHeaderSub$) this.metricsHeaderSub$.unsubscribe();
    }

    if (this.metricsHourData.observers.length == 0) {
      if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();
    }

    if (this.metricsTopProblemsData.observers.length == 0) {
      if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();
    }


    if (this.problemsData.observers.length == 0) {
      if (this.problemsSub$) this.problemsSub$.unsubscribe();
    }

    if (this.faultListData.observers.length == 0) {
      if (this.faultListSub$) this.faultListSub$.unsubscribe();
    }

    if (this.partCountData.observers.length == 0) {
      if (this.partCountSub$) this.partCountSub$.unsubscribe();
    }
  }

  getLastMetricsLoad():Observable<Date> {
    return this.lastMetricsLoadTime.asObservable();
  }

  currentShiftFlag():Observable<boolean> {
    return this.currentShiftSubject.asObservable();
  }

  getShiftData():Observable<shiftData> {
    return this.shiftData.asObservable();
  }

  private changeShift(shiftDate:string, shift:string) {
    let currentShiftData:shiftData =  this.shiftData.getValue();

    if (currentShiftData) {
      currentShiftData.currentShift = false;
      currentShiftData.display.shiftDate = shiftDate;
      currentShiftData.display.shift = shift;
      currentShiftData.dataLoading = true;
    } else {
      currentShiftData = {
        currentShift: false,
        dataLoading: true,
        current: {
          shiftDate: null,
          shift: null
        },
        previous: {
          shiftDate: null,
          shift: null
        },
        display: {
          shiftDate: shiftDate,
          shift: shift
        }
      }
    }

    this.shiftData.next(currentShiftData);
  }

  private setCurrentShift() {
    let currentShiftData:shiftData =  this.shiftData.getValue();

    if (currentShiftData) {
      currentShiftData.currentShift = true;
      currentShiftData.display = currentShiftData.current;
      currentShiftData.dataLoading = true;
    }

    this.shiftData.next(currentShiftData);
  }

  /**
   * Change shift date or go back to current shift, resets subscription to be up to date.
   * 
   * @param currentShift true if current shift or false if a past shift
   * @param shiftdate 
   * @param shift 
   */
  changeShiftDate(currentShift:boolean, shiftdate?:string, shift?:string) {
    console.log("SVC: changeShiftDate", currentShift, shiftdate, shift);
    this.currentShift = currentShift;
    this.shiftdate = shiftdate;
    this.shift = shift;

    this.currentShiftSubject.next(this.currentShift);

    if (currentShift) {
      this.setCurrentShift();
    } else {
      this.changeShift(shiftdate, shift);
    }

    // Since the shift has changed, any subscriptions that are still active force a reload to get the new shift's data
    if (this.metricsHeaderSub$ && !this.metricsHeaderSub$.closed) this.metricsHeaderSubscription();
    if (this.metricsHourSub$ && !this.metricsHourSub$.closed) this.metricsHourSubscription();
    if (this.metricsTopProblemsSub$ && !this.metricsTopProblemsSub$.closed) this.metricsTopProblemsSubscription();
    if (this.problemsSub$ && !this.problemsSub$.closed) this.problemsSubscription();
    if (this.faultListSub$ && !this.faultListSub$.closed) this.faultListSubscription();
    if (this.partCountSub$ && !this.partCountSub$.closed) this.partCountSubscription();
  }

  /**
   * Change the object that is being pull, resets subsriptions to be up to date
   * 
   * @param site Aurora Site
   * @param environment Aurora Environment
   * @param objectid Valid Proficy ObjectID
   * @param objecttype Valid type (FAB or ASSEMBLY)
   */
  changeObject(object:ObjectNode) {
    this.objectid = object.ObjectID;
    this.site = object.Site;
    this.environment = object.Environment;
    this.objecttype = object.ObjectType;
    this.PerJobObjectID = object.PerJobObject;

    // Since the object has changed, any subscriptions that are still active force a reload to get the new object's data
    if (this.metricsHeaderSub$ && !this.metricsHeaderSub$.closed) this.metricsHeaderSubscription();
    if (this.metricsHourSub$ && !this.metricsHourSub$.closed) this.metricsHourSubscription();
    if (this.metricsTopProblemsSub$ && !this.metricsTopProblemsSub$.closed) this.metricsTopProblemsSubscription();
    if (this.problemsSub$ && !this.problemsSub$.closed) this.problemsSubscription();
    if (this.faultListSub$ && !this.faultListSub$.closed) this.faultListSubscription();
    if (this.partCountSub$ && !this.partCountSub$.closed) this.partCountSubscription();
  }

  // =============== resource /header ======================
  metricsHeader(object:ObjectNode):Observable<GenericLambdaReturn<any>> {
    console.log("metricsHeader", object);
    if (object.Site && object.ObjectID) {
      if (this.objectid != object.ObjectID || this.site != object.Site || this.environment != object.Environment) {
        this.changeObject(object);
      }

      this.metricsHeaderSubscription();
    }

    return this.metricsHeaderData.asObservable();
  }

  private metricsHeaderSubscription():void {
    let params:any={};

    params.Site = this.site;
    params.Environment = this.environment;
    params.ObjectID = this.objectid;

    if (!this.currentShift) {
      params.ShiftSelection = "PREVIOUS";
      params.preShiftDate = this.shiftdate;
      params.preShift = this.shift;
    }

    let apiResource:string = this.objecttype == 'FAB' ? '/header/fab' : '/header';

    if (this.metricsHeaderSub$) this.metricsHeaderSub$.unsubscribe();

    this.metricsHeaderSub$ = this.get({
      ApiResource: apiResource,
      data: params,
      repeatCall: true,
      repeatRate: 5000,
      retryAttempts: -1
    }).subscribe(
      (out:GenericLambdaReturn<any>) => {
        this.metricsHeaderData.next(out);

        if (out) {
          this.lastMetricsLoadTime.next(out.Timestamp);

          // Check if need to signal that shift has changed
          let currentShiftData:shiftData =  this.shiftData.getValue();



          if (!currentShiftData || 
              currentShiftData.dataLoading ||
              (this.currentShift && (currentShiftData.display.shiftDate != out.Body.PRODUCTION.ShiftDate || currentShiftData.display.shift != out.Body.PRODUCTION.Shift))
              ) {
                if (this.currentShift) {
                  this.shiftdate = out.Body.PRODUCTION.ShiftDate;
                  this.shift = out.Body.PRODUCTION.Shift
                }
      
                let shift:shiftData = {
                  currentShift: this.currentShift,
                  dataLoading: false,
                  current: {
                    shiftDate: out.Body.PRODUCTION.ShiftDate,
                    shift: out.Body.PRODUCTION.Shift
                  },
                  previous: {
                    shiftDate: out.Body.PRODUCTION.shiftDatePrevious,
                    shift: out.Body.PRODUCTION.shiftPrevious            
                  },
                  display: {
                    shiftDate: this.shiftdate,
                    shift: this.shift
                  }
                }
     
                this.shiftData.next(shift); // Just need to set the shift data, don't have to call changeShiftDate because nothing will need that signal
          }

        }

      }
    );
  }

  metricsHeaderClose() {
    if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();
  }
  // =========================================================

  // =============== resource /metrics ======================
  metricsHour(object:ObjectNode, repeat:boolean=true):Observable<GenericLambdaReturn<any>> {
    if (object.Site && object.ObjectID) {
      if (this.objectid != object.ObjectID || this.site != object.Site || this.environment != object.Environment) {
        this.changeObject(object);
      }

      this.metricsHourSubscription();
    }

    return this.metricsHourData.asObservable();
  }

  private metricsHourSubscription():void {
    let params:any={};

    params.Site = this.site;
    params.Environment = this.environment;
    params.ObjectID = this.objectid;

    if (!this.currentShift) {
      params.ShiftSelection = "PREVIOUS";
      params.preShiftDate = this.shiftdate;
      params.preShift = this.shift;
    }

    if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();

    let apiResource:string = this.objecttype == 'FAB' ? '/metrics/fab' : '/metrics';

    this.metricsHourSub$ = this.get({
      ApiResource: apiResource,
      data: params,
      repeatCall: true,
      repeatRate: 5000,
      retryAttempts: -1
    }).subscribe(
      (out:GenericLambdaReturn<any>) => {
        this.metricsHourData.next(out);
      }
    );
  }

  metricsHourClose() {
    if (this.metricsHourSub$) this.metricsHourSub$.unsubscribe();
  }
  // =========================================================

  // =============== resource /topproblems ======================
  metricsTopProblems(object:ObjectNode, faults?:boolean):Observable<GenericLambdaReturn<any>> {
    if (object.Site && object.ObjectID) {
      if (this.objectid != object.ObjectID || this.site != object.Site || this.environment != object.Environment) {
        this.changeObject(object);
      }

      if (faults) {
        this.metricsTopProblemsSubscription(faults);
      } else {
        this.metricsTopProblemsSubscription();
      }
    }

    return this.metricsTopProblemsData.asObservable();
  }

  private metricsTopProblemsSubscription(faults?:boolean):void {
    let params:any={};

    params.Site = this.site;
    params.Environment = this.environment;
    params.ObjectID = this.objectid;

    if (faults) {
      params.Group = "FaultMessage"
    }

    if (!this.currentShift) {
      params.ShiftSelection = "PREVIOUS";
      params.preShiftDate = this.shiftdate;
      params.preShift = this.shift;
    }

    if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();

    this.metricsTopProblemsSub$ = this.get({
      ApiResource: "/topproblems",
      data: params,
      repeatCall: true,
      repeatRate: 5000,
      retryAttempts: -1
    }).subscribe(
      (out:GenericLambdaReturn<any>) => {
        this.metricsTopProblemsData.next(out);
      }
    );
  }

  metricsTopProblemsClose() {
    if (this.metricsTopProblemsSub$) this.metricsTopProblemsSub$.unsubscribe();
  }
  // =========================================================


  // =============== resource /problems ======================
  problems(object:ObjectNode, minduration:number=0):Observable<GenericLambdaReturn<any>> {
    if (object.Site && object.ObjectID) {
      if (this.objectid != object.ObjectID || this.site != object.Site || this.environment != object.Environment) {
        this.changeObject(object);
      }
    }

    this.minduration = minduration;

    this.problemsSubscription();

    return this.problemsData.asObservable();
  }

  private problemsSubscription():void {
    let params:any={};

    let object:string=`${this.site}/Other/${this.objectid}`;

    params.Object = object;
    params.MinDuration = this.minduration;

    if (!this.currentShift) {
      params.ShiftSelection = "PREVIOUS";
      params.preShiftDate = this.shiftdate;
      params.preShift = this.shift;
    }

    if (this.problemsSub$) this.problemsSub$.unsubscribe();

    this.problemsSub$ = this.get({
      ApiResource: "/problems",
      data: params,
      repeatCall: true,
      repeatRate: 120000
    }).subscribe(
      (out:GenericLambdaReturn<any>) => {
        this.problemsData.next(out);
      }
    );
  }

  problemsClose() {
    if (this.problemsSub$) this.problemsSub$.unsubscribe();
  }

  // Following return the observable directly to the component, no shared data
  partCount(object:ObjectNode):Observable<GenericLambdaReturn<any>> {
    if (object.Site && object.ObjectID) {
      if (this.objectid != object.ObjectID || this.site != object.Site || this.environment != object.Environment) {
        this.changeObject(object);
      }

      this.partCountSubscription();
    }

    return this.partCountData.asObservable();
  }

  private partCountSubscription(): void {
    let params:any={};

    params.Site = this.site;
    params.Environment = this.environment;
    params.ObjectID = this.objectid;
    params.PerJobObjectID = this.PerJobObjectID;

    if (!this.currentShift) {
      params.ShiftSelection = "PREVIOUS";
      params.ShiftDate = this.shiftdate;
      params.Shift = this.shift;
    }

    if (this.partCountSub$) this.partCountSub$.unsubscribe();

    this.partCountSub$ = this.get({
      ApiResource: "/metrics/parts",
      data: params,
      repeatCall: true
    }).subscribe(
      (out:GenericLambdaReturn<any>) => {
        this.partCountData.next(out);
      }
    )
  }

  teamList(site:string, objectid: string):Observable<GenericLambdaReturn<teams>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;

    return this.get({
      ApiResource: "/teamlist",
      data: params,
      repeatCall: true
    })
  }

  problemList(site:string, objectid: string):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;

    return this.get({
      ApiResource: "/problemlist",
      data: params,
      repeatCall: true
    })
  }

  updateFault(team:string, problem:string, problemL2:string, problemL3:string, notes:string, recordId:number, faultValue:number, faultMessage:string, hour:string, shift:string, shiftDate:string, objectId:string, site:string, inProblem:string, inProblemL2:string, inProblemL3:string, inNotes:string,inTeam:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};

    params.recordId = recordId;
    params.faultValue = faultValue;
    params.faultMessage = faultMessage;
    params.problem = problem;
    params.problemLevel2 = problemL2;
    params.problemLevel3 = problemL3;
    params.notes = notes;
    params.hour = hour;
    params.shift = shift;
    params.shiftDate = shiftDate;
    params.objectId = objectId;
    params.site = site;
    params.inProblem = inProblem;
    params.inProblemL2 = inProblemL2;
    params.inProblemL3 = inProblemL3;
    params.inNotes = inNotes;
    params.inTeam = inTeam;
    params.Team = team;

    console.log(params)

    return this.post({
      ApiResource: "/faultlist",
      data: params,
      repeatCall: false
    })
  }

  saveManualProblem(site:string, objectid:string, hour:number, category:string, categoryL2:string, categoryL3:string, notes:string, startTime: string, duration:number, ID?:string[], manual?:boolean, team?:string ):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;
    params.Object = object;
    params.hour = hour;
    params.category = category;
    params.categoryL2 = categoryL2;
    params.categoryL3 = categoryL3;
    params.notes = notes;
    if (manual) {
      params.starttime = startTime; // TODO: this lambda requires a space at the beginning - we should not require this
      params.duration = duration;
      params.manual = true;
    }
    params.team = team;
    if (ID != null && ID.length > 0) {
      params.ID = ID
    }
    return this.post({
      ApiResource: "/problems",
      data: params,
      repeatCall: false
    })
  }

  deleteProblem(site:string,objectid:string,ID:number[]):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.ProbID = ID.toString();
    params.ChangeType = 'delete';


    return this.delete({
      ApiResource: "/problems",
      data: params,
      repeatCall: false
    })
  }

  clearProblem(site:string,objectid:string,ID:number[]):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.ProbID = ID.toString();
    params.ChangeType = 'clear';


    return this.delete({
      ApiResource: "/problems",
      data: params,
      repeatCall: false
    })
  }  



  rejects(site:string, objectid:string, count:number):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.Count = count;

    return this.get({
      ApiResource: "/rejects",
      data: params,
      repeatCall: true,
      repeatRate: 60000
    })
  }

  repairs(site:string, objectid:string, count:number):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.Count = count;

    return this.get({
      ApiResource: "/repairs",
      data: params,
      repeatCall: true,
      repeatRate: 60000
    })
  }

  zones(site:string, objectid:string):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;

    return this.get({
      ApiResource: "/zone",
      data: params,
      repeatCall: false
    })
  }

  shiftNoteUpdate(site:string, objectid:string, note:string, shiftDate:string, shift:string, zone:string, id:string=""):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.Note = note;
    params.ShiftDate = shiftDate;
    params.Shift = shift;
    params.Zone = zone;
    params.id = id;

    return this.post({
      ApiResource: "/shiftnoteupdate",
      data: params,
      repeatCall: false
    })
  }

  hourNoteUpdate(site:string, objectid:string, note:string, action:string, shiftDate:string, shift:string, hour:string, id:string=""):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.Note = note;
    params.Action = action;
    params.ShiftDate = shiftDate;
    params.Shift = shift;
    params.id = id;
    params.Hour = hour;

    return this.post({
      ApiResource: "/hournoteupdate",
      data: params,
      repeatCall: false
    })
  }

  // =============== fault list ======================
  faultList(object:ObjectNode, shiftDate:string, shift:string, hour:number):Observable<GenericLambdaReturn<any>> {
    if (object.Site && object.ObjectID && hour) {
      if (this.objectid != object.ObjectID || this.site != object.Site || this.environment != object.Environment) {
        this.changeObject(object);
      }

      this.shiftdate = shiftDate;
      this.shift = shift;

      this.hour = (hour == -1 ? 'ALL' : hour.toString()); // If hour is -1 then pass ALL to the request ot get all hours
    }

    this.faultListSubscription();

    return this.faultListData.asObservable();
  }

  private faultListSubscription():void {
    let params:any={};

    params.objectid = this.objectid;
    params.site = this.site;
    params.shiftDate = this.shiftdate;
    params.shift = this.shift;
    params.hour = this.hour;
    console.log("faultListSubscription", params);
    if (this.faultListSub$) this.faultListSub$.unsubscribe();

    this.faultListSub$ = this.get({
      ApiResource: "/faultlist",
      data: params,
      repeatCall: true,
      repeatRate: 10000
    }).subscribe(
      (out:GenericLambdaReturn<any>) => {
        this.faultListData.next(out);
      }
    );
  }

  faultListClose() {
    if (this.faultListSub$) this.faultListSub$.unsubscribe();
  }

  pareto(site:string,objectid:string,startDate:string, endDate:string, level:number, filterLevel1:string='', filterLevel2:string='', shift:string=''):Observable<GenericLambdaReturn<any>> {
    let params:any={};
    let object:string=`${site}/Other/${objectid}`;

    params.Object = object;
    params.StartDate = startDate;
    params.EndDate = endDate;
    params.Level = level;
    params.FilterLevel1 = filterLevel1;
    params.FilterLevel2 = filterLevel2;
    params.Shift = shift;

    return this.get({
      ApiResource: "/pareto",
      data: params,
      repeatCall: true
    })
  }

}

export type teams = {
  Teams: team[];
}

export type team = {
  Team: string;
}

export type shiftData = {
  currentShift:boolean;
  dataLoading:boolean;
  previous: shift;
  current: shift;
  display: shift;
}

export type shift = {
  shift: string;
  shiftDate: string;
}