<div class="object-content">
    <div>
      <button [disabled]="!adminFlag" mat-icon-button color="link" (click)="editObject(false)"><mat-icon>add</mat-icon>Create New Object</button>
    </div>    
      <div *ngIf="fullSizeMatcher.matches; else cardDisplay">
          <mat-table [dataSource]="objectList">          
              <ng-container matColumnDef="Section">
                <mat-header-cell *matHeaderCellDef>Section</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.Section}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="SubSection">
                <mat-header-cell *matHeaderCellDef>Sub Section</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.SubSection}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="ObjectID">
                <mat-header-cell *matHeaderCellDef>Object ID</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.ObjectID}}</mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="Description">
                <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.ObjectDescription}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="ObjectType">
                <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell *matCellDef="let d">
                  <div *ngIf="d.ObjectType === 'GROUP'">Group</div>
                  <div *ngIf="d.ObjectType === 'ASSEMBLY'">Assembly</div>
                  <div *ngIf="d.ObjectType === 'FAB'">Fab</div>
                </mat-cell>
              </ng-container>                    

              <ng-container matColumnDef="ProblemGroupDescription">
                <mat-header-cell *matHeaderCellDef>Problem Group</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.ProblemGroupDescription}}</mat-cell>
              </ng-container>                            
      
              <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                  <mat-cell *matCellDef="let d; let i = index">
                    <button [disabled]="!adminFlag" mat-button color="primary" (click)="editObject(true, i)"><mat-icon>create</mat-icon>Edit</button>
                    <button [disabled]="!adminFlag" mat-button color="warn" (click)="deleteObject(d.Site, d.Environment, d.ObjectID)"><mat-icon>delete_forever</mat-icon>Delete</button>
                    <button [disabled]="!adminFlag" mat-button color="primary" (click)="editProblems(d.ProblemGroup, d.ProblemGroupDescription)"><mat-icon>account_tree</mat-icon>Edit Problem Group</button>
                    <button [disabled]="!adminFlag" mat-button color="basic" (click)="andonGroups(d.Site, d.Environment, d.ObjectID, d.Description)"><mat-icon>play_for_work</mat-icon>Andon Groups</button>
                    <button *ngIf="d.ObjectType === 'FAB'" [disabled]="!adminFlag" mat-icon-button color="primary" (click)="openOAConfig(d.Site, d.Environment, d.ObjectID)"><mat-icon>settings</mat-icon>OA Challenge</button>
                  </mat-cell>
                </ng-container>  
      
              <mat-header-row *matHeaderRowDef="displayedObjectColumns; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedObjectColumns;"></mat-row>
            </mat-table>
  
      </div>
      <ng-template #cardDisplay>
          <mat-card *ngFor="let obj of objectList; let i = index" class="objCards mat-elevation-z10">
            <mat-card-header>
              <mat-card-title>{{obj.Section}} - {{obj.ObjectID}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              Description: {{obj.Description}} <br>
              Count Collection: <span *ngIf='obj.ManCount == 0'>Automatic</span>
                                <span *ngIf='obj.ManCount == 1'>Manual</span><br>
              Problem Collection: <span *ngIf='obj.ManProb == 0'>Automatic</span>
                                  <span *ngIf='obj.ManProb == 1'>Manual</span>
                                  <span *ngIf='obj.ManProb == 2'>Auto and Manual</span><br>
              Problem Group: {{obj.ProblemGroupDescription}}<br>
            </mat-card-content>
            <mat-card-footer>
              <mat-card-actions>
                <button [disabled]="!adminFlag" mat-button color="basic" (click)="andonGroups(obj.Site, obj.Environment, obj.ObjectID, obj.Description)"><mat-icon>play_for_work</mat-icon>Andon Groups</button>
                <button [disabled]="!adminFlag" mat-button color="primary" (click)="editObject(true, i)"><mat-icon>create</mat-icon>Edit</button>                      
                <button [disabled]="!adminFlag" mat-button color="warn" (click)="deleteObject(obj.Site, obj.Environment, obj.ObjectID)"><mat-icon>delete_forever</mat-icon>Delete</button>
              </mat-card-actions>
            </mat-card-footer>
          </mat-card>
      </ng-template>
  </div>
  