import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ObjectListService, ObjectNode } from 'src/app/services/object-list.service';

@Component({
  selector: 'app-object-list',
  templateUrl: './object-list.component.html',
  styleUrls: ['./object-list.component.scss']
})
export class ObjectListComponent implements OnInit, OnDestroy {
  
  
  treeControl = new NestedTreeControl<ObjectNode>(node => node.children);
  teamSource = new MatTreeNestedDataSource<ObjectNode>();
  
  ObjectListSubscription$:Subscription;
  
  hasChild = (_: number, node: ObjectNode) => !!node.children && node.children.length > 0; 
  expandedNodes: ObjectNode[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcObjectList:ObjectListService
  ) { 

  }

  ngOnInit(): void {
    let plantName:string = this.route.snapshot.paramMap.get("plant_name") ? this.route.snapshot.paramMap.get("plant_name") : '';
    plantName = plantName == 'AP4' ? 'PCE' : plantName; // Handle Plastics coming in as AP4 but Hour by Hour using PCE

    if (this.ObjectListSubscription$) this.ObjectListSubscription$.unsubscribe();    
    this.ObjectListSubscription$ = this.svcObjectList.objectList().subscribe(
      out => {
        this.teamSource.data = out.Body;

        if (plantName != '') {
          let filteredArray:ObjectNode[] = this.teamSource.data.filter( record => record.search == plantName);

          if (filteredArray.length > 0) {
            // If Plant name returned any filtered rows then use those
            this.teamSource.data = filteredArray;
          } else {
            // Else remove the plant_name from the route since it is invalid and show the users everything
            this.router.navigate(["/objectlist"]);
          }

          this.expandTree("plant_name", this.teamSource.data);
        }
      }
    );
    
  }

  ngOnDestroy():void{
    if (this.ObjectListSubscription$) this.ObjectListSubscription$.unsubscribe(); 
  }

  navigatePlant(isplant: boolean, site:string){
    if (isplant) {
      this.router.navigate(["/objectlist",site]);
    }
  }

  navigate(site: string, objectid: string) {
    console.log("site", site, objectid);
    this.router.navigate(["/metrics", site, objectid]);

  }
    
  expandTree(param: string, nodes: ObjectNode[]){
    if (this.route.snapshot.paramMap.get(param) !== undefined){
      var valid = false;
      var expandedNode: ObjectNode;  
      nodes.forEach(node => {
        var routeParam: string = this.route.snapshot.paramMap.get(param);

        if (param == "plant_name" && routeParam == 'AP4') {
          // Handle case where expanding plant name and it is in Plastics
          routeParam = "PCE";
        }

        console.log(routeParam)
        if(((node.search).toString()).toLowerCase() == routeParam.toLowerCase()){
          expandedNode = node;
          valid = true;
        }
      });

      if(valid){
        console.debug("Valid " + param + " parameter supplied, expanding " + this.route.snapshot.paramMap.get(param));
        this.treeControl.toggle(expandedNode);
        this.expandedNodes.push(expandedNode);
      }else{
        console.warn("Invalid " + param + " parameter provided");
      }
    }
    }


}
