<div *ngIf="isReady" class="rootCauseComp">
    <div class="date-row">
        <gea-mes-datepicker
            class="dateRangeBut"
            matTooltip="Select Date" 
            (onDateSelect)="onDateSelection($event)" 
            (onDateInit)="onDateSelection($event)"
            singleDate="false" 
            showLabel="true"
            showCurrentShift="false"
            defaultRange="Today"
            showRangeLabel="true"
            autoApply="true"
            [index]=DatePickerIndex>
        </gea-mes-datepicker>
    </div>

    <mat-table [dataSource]="topRootCause">

        <ng-container matColumnDef="RootCause_Level1">
          <mat-header-cell *matHeaderCellDef>Level 1</mat-header-cell>
          <mat-cell *matCellDef="let d">{{d.RootCause_Level1}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="RootCause_Level2">
          <mat-header-cell *matHeaderCellDef>Level 2</mat-header-cell>
            <mat-cell *matCellDef="let d">{{d.RootCause_Level2}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="RootCause_Level3">
          <mat-header-cell *matHeaderCellDef>Level 3</mat-header-cell>
            <mat-cell *matCellDef="let d">{{d.RootCause_Level3}}</mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="Downtime">
          <mat-header-cell *matHeaderCellDef>Downtime</mat-header-cell>
            <mat-cell *matCellDef="let d">{{d.Downtime | TimeFormat}}</mat-cell>
        </ng-container>
    
            
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

</div>

<div *ngIf="loadingData">
  <mat-spinner></mat-spinner>
</div>
